<template>
  <div class="home">
    <div class="header">
      <el-carousel indicator-position="outside" :interval="6000" height="80vh">
        <el-carousel-item v-for="(item,index) in binnerList" :key="index">
          <el-image style="width: 100vw; height:80vh;" :src="item.path" fit="fill"></el-image>
          <div class="binnerText">
            <div class="binnerTitle">{{item.title}}</div>
            <div class="binnerContent">{{item.content}}</div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="nav">
        <div class="logo">
          <el-image style="width: 40px; height: 40px" :src="require('@/assets/image/yt-logo-white.png')" fit="contain"></el-image>
          <span>世纪银通</span>
        </div>
        <ul class="menu-item">
          <li class="hvr-underline-from-center" @click.prevent="custormAnchor('introduce')">简介</li>
          <li class="hvr-underline-from-center" @click.prevent="custormAnchor('culture')">企业文化</li>
          <li class="hvr-underline-from-center" @click.prevent="custormAnchor('honor')">发展历程</li>
          <li class="hvr-underline-from-center" @click.prevent="custormAnchor('partner')">合作伙伴</li>
          <li class="hvr-underline-from-center" @click.prevent="custormAnchor('staff')">员工</li>
          <li class="hvr-underline-from-center" @click.prevent="custormAnchor('contact')">联系我们</li>
        </ul>
      </div>

    </div>
    <div class="content">
      <!-- 导航模块 -->
      <div class="NavMenu">
        <el-divider></el-divider>
        <div class="NavMenuContent">
          <div class="item vr-glow" @click.prevent="custormAnchor('introduce')">
            <el-image :src="require('@/assets/image/jianJie.jpg')" fit="fill"></el-image>
            <div>
              <p>公司简介</p>
            </div>
          </div>
          <div class="item vr-glow" @click.prevent="custormAnchor('culture')">
            <el-image :src="require('@/assets/image/pic.jpg')" fit="fill"></el-image>
            <div>
              <p>企业文化</p>
            </div>
          </div>
          <div class="item vr-glow" @click.prevent="custormAnchor('environment')">
            <el-image :src="require('@/assets/image/huanJing3.jpg')" fit="fill"></el-image>
            <div>
              <p>工作环境</p>
            </div>
          </div>
        </div>

      </div>
      <!-- 公司简介 -->
      <div class="moduel" id="introduce">
        <div class="title">公司简介</div>
        <div class="text">
          <p class="textItem indent">四川世纪银通科技有限公司2012年9月于成都成立，注册资本四千万元。</p>
          <p class="textItem indent">自成立以来，深耕电信运营商业务，从为运营商提供技术及营销服务，到与运营商共同创新开展消费分期、供应链金融、融资租赁等业务。是中国移动、中国电信、中国联通三大运营商消费分期领域的战略合作伙伴。</p>
          <p class="textItem indent">数年发展，世纪银通已获取商业保理、融资租赁牌照；积累了支付宝、网商银行、海尔金控、江西金发、中证信用、建设银行、邮储银行、浦发银行、上海银行、中国银联等金融战略合作伙伴；组建了拥有丰富运营商及金融机构工作经验、具优秀实践和创新能力的核心团队；搭建了高效稳定的业务、风控系统。</p>
          <p class="textItem indent">世纪银通始终秉承“专注、专业、创新、满意”的企业核心理念，以广泛的客户渠道，专业的顾客服务，诚信的经营口碑，有力的合作伙伴，在行业中名列前茅，是成都武侯区纳税大户，四川省高新技术企业。</p>
        </div>
      </div>
      <!-- 发展历程 -->
      <div class="moduel" id="honor">
        <div class="title">发展历程</div>
        <div class="steps">
          <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide class="stepItem" v-for="(item,index) in stepList" :key="index">
              <div class="stepTitle">{{item.title}}</div>
              <ul class="stepContent">
                <li v-for="(ele,index) in item.stepContent" :key="index">
                  <i class="el-icon-caret-right"></i><span>{{ele}}</span>
                </li>
              </ul>
            </swiper-slide>
            <div class="swiper-scrollbar"></div>
          </swiper>
        </div>
      </div>
      <!-- 企业文化 -->
      <div class="moduel" id="culture">
        <div class="title">企业文化</div>
        <div class="float">
          <div class="left">
            <div class="text">
              <p class="title2">正直</p>
              <p class="textItem">坚守底线，以德为先，坦诚公正不唯上</p>
              <p class="title2">进取</p>
              <p class="textItem">长期艰苦奋斗，坚持自我批判</p>
              <p class="title2">协作</p>
              <p class="textItem">团队合作，高效执行</p>
              <p class="title2">诚信</p>
              <p class="textItem">以诚相待、以信相交</p>
            </div>
          </div>
          <div class="right">
            <el-image :src="require('@/assets/image/pic.jpg')" fit="contain"></el-image>
          </div>
        </div>
      </div>
      <!-- 合作伙伴 -->
      <div class="moduel" id="partner">
        <div class="title">合作伙伴</div>
        <ul class="list">
          <li class="hvr-grow" v-for="(item,index) in partnerList" :key="index">
            <el-image :src='item.iconPath' fit="contain" :style="{'transform':'scale('+item.scale +')'}"></el-image>
          </li>
        </ul>
      </div>
      <!-- 员工活动 -->
      <div class="moduel" id="staff">
        <div class="title">员工活动</div>
        <div class="imgText">
          <el-image style="height:320px" :src="require('@/assets/image/2020nh.jpg')" fit="cover"></el-image>
          <div class="text">不定期组织企业员工活动，依据企业自身文化，设计安排相关的户外徒步、室内益智等项目，“同心同步同进步”，合而不同，求同存异，培养包容性高的团队合作精神，建立团队默契，让员工充分理解和认知企业精神，有效提升员工归属感和荣誉感。</div>
        </div>
      </div>
      <!-- 工作环境 -->
      <div class="moduel" id="environment">
        <div class="title-lf">工作环境<span>创造一个平等、和谐、多元文化融合发展的工作环境</span></div>
        <div class="workEnv">
          <div class="left">
            <el-image :src="require('@/assets/image/zlq.jpg')" fit="contain"></el-image>
          </div>
          <div class="right">
            <div class="text">
              <p class="title2">成都棕榈泉国际中心</p>
              <p class="textItem">这是成都最高级别的国际标准超甲级写字楼之一，集数字化、智能化于一体的智慧大厦。这座“互联互通”的大厦，象征着互联网将各个角落互相连通，更体现了隐痛是一家专注连接人与人、人和服务、以及未来人和设备的互联网高科技企业。为员工打造了舒适、创新，体现公司文化特色的办公环境。</p>
            </div>
          </div>
        </div>
        <!-- <div class="workEnv"></div> -->
      </div>
    </div>
    <div class="footer">
      <div class="moduel" id="contact">
        <div class="title-lf">联系我们</div>
        <div class="float">
          <div class="left">
            <div class="text">
              <p class="title2">TEL</p>
              <p class="textItem">028-8544 2791</p>
              <p class="title2">地址</p>
              <p class="textItem">四川省成都市武侯区天府大道中段199号</p>
              <p class="title2">邮编</p>
              <p class="textItem">610041</p>
            </div>
          </div>
          <div class="right">
            <div id="mapContainer"></div>
            <!-- <el-image :src="require('@/assets/image/pic.jpg')" fit="contain"></el-image> -->
          </div>
        </div>
      </div>
    </div>
    <div class="bottom" @click="goPage">蜀ICP备12027854号-8</div>
    <!-- 回到顶部 -->
    <el-backtop></el-backtop>
  </div>
</template>
<script>
import AMap from "AMap";
export default {
  data() {
    return {
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 20,
        // 如果需要分页器
        pagination: {
          el: '.swiper-pagination',
          // clickable: true,
        },
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // 如果需要滚动条
        scrollbar: {
          el: '.swiper-scrollbar',
        },
      },
      binnerList: [
        { title: "消费金融产品推广", content: "借助世纪银通广泛的线下商家网点，为保险等金融产品提供落地推广能力。", path: require('@/assets/image/binner-5.jpg') },
        { title: "专业 专注 创新 满意", content: "近40人的专业客服队伍，提供7*24小时优质服务。", path: require('@/assets/image/binner-4.jpg') },
      ],
      infoWindow: "",
      partnerList: [
        {
          iconPath: require('@/assets/image/yiPay.png'),
          scale: '0.5'
        },
        {
          iconPath: require('@/assets/image/woPay.png'),
          scale: '1.2'
        },
        {
          iconPath: require('@/assets/image/yinLian.png'),
          scale: '0.7'
        },
        {
          iconPath: require('@/assets/image/aliPay.png'),
          scale: '1'
        },
        {
          iconPath: require('@/assets/image/huaBei.png'),
          scale: '0.5'
        },
        {
          iconPath: require('@/assets/image/psbc_hLogo.png'),
          scale: '0.9'
        },
        {
          iconPath: require('@/assets/image/shYh.png'),
          scale: '0.8'
        },
        {
          iconPath: require('@/assets/image/haiErJf.png'),
          scale: '1.1'
        },
        {
          iconPath: require('@/assets/image/wangShangBink.png'),
          scale: '0.8'
        },
        {
          iconPath: require('@/assets/image/zhiMa.png'),
          scale: '0.7'
        },
        {
          iconPath: require('@/assets/image/maYiJinFu.png'),
          scale: '0.5'
        },
        {
          iconPath: require('@/assets/image/jsyh.png'),
          scale: '0.9'
        },

      ],
      active: 0, // 发展历程时间轴
      stepList: [
        {
          title: "2020", stepContent: [
            "与中国移动消费金融达成战略合作伙伴",
            '全面启动保理业务'
          ]
        },
        {
          title: "2019", stepContent: [
            '消金合作商家突破10000家',
            '全年消金业务放款达9亿',
          ]
        },
        {
          title: "2018", stepContent: [
            '引入战略合作伙伴山西通富集团',
            '向运营商消费分期业务转型',
            '信用购平台上线运营',
            '取得融资租赁和保理资质',
            '全年消金业务放款突破5000万',

          ]
        },
        {
          title: "2017", stepContent: [
            '全年充值缴费业务累计突破400亿',
            '获得国家科技部颁发的科技成果评价证书',
            '被列入“十三五”四川省“专精特新”中小企业（第一批）培育名单',
            '成都市“纳税大户”',
          ]
        },
        {
          title: "2015", stepContent: [
            '成都市“纳税大户”',
            '全年话费充值业务累计突破200亿',
            '自营渠道充值宝上线',
          ]
        },
        {
          title: "2014", stepContent: [
            '通过四川省高新技术企业认证',
            '全年话费充值业务累计突破100亿',
            '充值合作网点突破10000家',
            '与工商银行、红旗连锁、舞东风达成战略合作',
          ]
        },
        {
          title: "2013", stepContent: [
            '电信、移动、联通就话费充值业务达成合作',
            '取得第二类增值电信经营许可',
            '合作渠道商突破100家',
            '全年话费充值业务累计突破10亿',
            '314PAY电子支付平台上线',
            '武侯区纳税大户',
          ]
        },
        {
          title: "2012", stepContent: [
            '公司注册成立',
          ]
        },
      ]
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  mounted() {
    this.showMap();
    // this.swiper.slideTo(3, 1000, false)
  },
  methods: {
    custormAnchor(index) { //锚点滚动到固定位置      
      let anchorElement = document.getElementById(index);
      if (anchorElement) {
        anchorElement.scrollIntoView({
          behavior: "smooth"
        });
      }
    },
    showMap() {
      let map = new AMap.Map("mapContainer", {
        resizeEnable: true,
        center: [104.069793, 30.554029],
        zoom: 14
      });

      // 坐标点
      let marker = new AMap.Marker({
        position: new AMap.LngLat(104.069793, 30.554029), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: "成都"
      });

      // 将创建的点标记添加到已有的地图实例：
      map.add(marker);
      // 同时引入工具条插件，比例尺插件和鹰眼插件
      AMap.plugin(
        [
          "AMap.ToolBar",
          "AMap.Scale",
          "AMap.MapType",
        ],
        function () {
          // 在图面添加工具条控件，工具条控件集成了缩放、平移、定位等功能按钮在内的组合控件
          map.addControl(new AMap.ToolBar());

          // 在图面添加比例尺控件，展示地图在当前层级和纬度下的比例尺
          map.addControl(new AMap.Scale());

          // 在图面添加鹰眼控件，在地图右下角显示地图的缩略图
          map.addControl(new AMap.OverView({ isOpen: true }));

          // 在图面添加类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
          map.addControl(new AMap.MapType());

          // 在图面添加定位控件，用来获取和展示用户主机所在的经纬度位置
          map.addControl(new AMap.Geolocation());
        }
      );

      // 这里是信息窗体，不需要可以删除
      this.infoWindow = new AMap.InfoWindow({
        isCustom: true, //使用自定义窗体
        content: this.createInfoWindow("服务区", ""),
        offset: new AMap.Pixel(16, -25)
      });
      this.infoWindow.setContent(
        this.createInfoWindow()
      );
      this.infoWindow.open(map, { Q: 30.554029, R: 104.069793, lng: 104.069793, lat: 30.554029 });
      marker.on("mouseover", e => {
        this.infoWindow.setContent(
          this.createInfoWindow(e.target.name,)
        );
        this.infoWindow.open(map, e.target.getPosition());
      });
    },
    // 自定义信息窗体
    createInfoWindow() {
      let info = `
      <div style="padding:10px; color:#0052d9; background:#fff; outline:2px solid #0052d9; margin-bottom:4px; text-align:left;">
        <div style="font-family:'HarmonyOsSansM'; font-style: italic; font-size:18px;margin-bottom:4px; ">棕榈泉国际中心</div>
        <div style ="font-family:'HarmonyOsSansR'; color: #606266; font-weight: 500; font-size: 14px;">成都市武侯区天府大道中段199号</div>
      </div>
     `

      return info;
    },
    goPage() {
      window.open('https://beian.miit.gov.cn')
    }
  }
};
</script>
<style lang="scss">
.el-carousel__button {
  width: 60px !important;
  height: 6px !important;
}
.el-carousel__indicators--outside button {
  background: #fff !important;
}
.el-carousel__indicators--outside .el-carousel__indicator:hover button {
  background: #0052d9 !important;
  opacity: 1;
}
.el-backtop,
.el-calendar-table td.is-today {
  color: #0052d9;
}
.el-carousel__arrow--left,
.el-carousel__arrow--right {
  display: none !important;
}

div.home {
  width: 100vw;
  min-height: 100vh;
  font-family: "HarmonyOsSansR";
  div.header {
    position: relative;
    width: 100vw;
    height: 80vh;
    overflow: hidden;
    cursor: pointer;
    div.nav {
      position: absolute;
      left: 200px;
      top: 10px;
      z-index: 2;
      width: 70vw;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 10vh;
      div.logo {
        display: flex;
        align-items: center;
        & > span {
          margin-left: 6px;
          font-size: 24px;
          font-weight: bold;
          font-family: "HarmonyOsSansM" !important;
        }
      }
      ul.menu-item {
        z-index: 4;
        font-weight: bold;
        display: flex;
        align-items: center;
        opacity: 1;
        transition: opacity 0.3s linear;
        transform: translate(0px, 0);
        li {
          margin-left: 30px;
          padding: 10px 2px 10px;
          text-align: center;
          font-family: HarmonyOsSansR;
          font-size: 18px;
          font-weight: bold;
        }
        div.el-image {
          img {
            height: auto;
          }
        }
      }
    }
    div.binnerText {
      position: absolute;
      left: 18vw;
      top: 28vh;
      color: #fff;
      z-index: 9;
      text-align: left;
      font-family: "HarmonyOsSansR";
      div.binnerTitle {
        font-size: 48px;
        margin-bottom: 20px;
      }
      div.binnerContent {
        font-size: 18px;
      }
    }
    ul.el-carousel__indicators {
      position: absolute;
      right: 0;
      bottom: 100px;
    }
  }
  div.content {
    width: 100vw;
    background: #fbfbfb;
    div.NavMenu {
      width: 66vw;
      margin: -100px auto 0;
      .el-divider {
        z-index: 99;
        background-color: #fff;
        height: 2px;
      }
      div.NavMenuContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        div.item {
          width: 21vw;
          height: 24vh;
          position: relative;
          z-index: 999;
          & > div {
            width: 100%;
            height: 24vh;
            line-height: 24vh;
            color: #fff;
            font-size: 30px;
            text-align: center;
            position: absolute;
            font-style: italic;
            top: 0;
            left: 0;
            z-index: 999;
            background: rgba($color: #000000, $alpha: 0.4);
          }
          p {
            height: 20%;
            line-height: 100%;
            display: inline-block;
            vertical-align: middle;
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            position: relative;
            overflow: hidden;
          }
          p:before {
            content: "";
            position: absolute;
            z-index: -1;
            left: 51%;
            right: 51%;
            bottom: 0;
            background: #fff;
            height: 4px;
            -webkit-transition-property: left, right;
            transition-property: left, right;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
          }
          img {
            width: 100%;
            height: 24vh;
            transform: scale(1);
            transition: all 0.5s;
          }
        }
        div.item:hover {
          & > div {
            background: rgba($color: #0052d9, $alpha: 0.88);
          }
          p:before {
            left: 0;
            right: 0;
          }
          img {
            transform: scale(1.1);
          }
        }
      }
    }
    div.title {
      text-align: center;
      font-size: 30px;
      font-family: "HarmonyOsSansM";
      color: #2a2e2e;
      padding: 20px 0;
      font-style: italic;
      margin-bottom: 30px;
    }
    div.title-lf {
      text-align: left;
      font-size: 30px;
      font-family: "HarmonyOsSansR";
      color: #2a2e2e;
      padding: 20px 0;
      font-style: italic;
      margin-bottom: 30px;
      span {
        margin-left: 220px;
        font-size: 14px;
        line-height: 25px;
        color: #5f6464;
        font-style: normal;
      }
    }
    div.moduel {
      padding: 40px 22vw 80px;
      background: #fbfbfb;

      div.text {
        text-align: left;
        color: #606266;
        font-weight: 500;
        font-size: 18px;
        p.textItem {
          line-height: 32px;
        }
        p.textItem.indent {
          text-indent: 40px;
        }
      }
      div.float {
        display: flex;
        align-items: center;
        justify-content: space-between;
        div.right {
          width: 50%;
          height: 50%;
        }
        div.text {
          text-align: left;
          color: #606266;
          font-weight: 500;
          font-style: 16px;
          p.title2 {
            font-size: 24px;
            font-family: "HarmonyOsSansM";
            color: #2a2e2e;
            font-style: italic;
            padding: 2px 0;
          }
          p.textItem {
            line-height: 32px;
            padding: 2px 0 16px;
          }
        }
      }
      ul.list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        li {
          width: 25%;
          margin: 10px 0;
          & > p {
            padding: 14px 0;
            text-align: center;
          }
        }
      }
      div.steps {
        display: flex;
        flex-wrap: wrap;
        .swiper-container {
          width: 100%;
          height: 100%;
          .swiper-wrapper {
            div.stepItem {
              text-align: left;
              width: 22% !important;
              div.stepTitle {
                font-size: 24px;
                padding-top: 40px;
                line-height: 36px;
                font-family: "HarmonyOsSansM";
                padding-bottom: 8px;
                border-bottom: 1px solid #d8d9dc;
                margin-bottom: 18px;
                color: #2a2e2e;
                font-style: italic;
              }
              ul.stepContent {
                li {
                  span {
                    padding: 4px 0;
                    color: #606266;
                    line-height: 20px;
                    font-size: 14px;
                    font-family: "HarmonyOsSansR";
                  }
                  i {
                    color: #606266;
                    font-size: 14px;
                    margin-right: 2px;
                  }
                }
              }
            }
            div.stepItem:first-of-type {
              div.stepTitle {
                color: #0052d9;
              }
              ul.stepContent {
                li {
                  span {
                    color: #0052d9;
                  }
                  i {
                    color: #0052d9;
                  }
                }
              }
            }
          }
        }
      }
      div.imgText {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .el-image {
          width: 50%;
        }
        div.text {
          color: #606266;
          font-size: 20px;
          width: 50%;
          padding: 0 20px;
          text-align: left;
          line-height: 30px;
          text-indent: 40px;
        }
      }
      div.workEnv {
        display: flex;
        align-items: center;
        height: auto;
        div.left {
          img {
            width: 300px;
            height: auto;
            transform: scale(1);
            transition: all 0.5s;
            cursor: pointer;
          }
        }
        div.left:hover img {
          transform: scale(1.05);
        }
        div.right {
          padding-left: 40px;
        }
        div.text {
          text-align: left;
          color: #606266;
          font-weight: 500;
          font-style: 16px;
          p.title2 {
            font-size: 44px;
            font-family: "HarmonyOsSansM";
            color: #333333;
            padding: 10px 0;
          }
          p.textItem {
            line-height: 26px;
            padding: 2px 0 10px;
          }
        }
      }
    }
    div.moduel:nth-child(odd) {
      background: #fff;
    }
  }
  div.footer {
    width: 100vw;
    div.moduel {
      margin: 40px 0;
      padding: 20px 22vw;
      div.title-lf {
        text-align: left;
        font-size: 30px;
        font-family: "HarmonyOsSansR";
        color: #2a2e2e;
        padding: 20px 0;
        font-style: italic;
        margin-bottom: 30px;
        span {
          margin-left: 220px;
          font-size: 14px;
          line-height: 25px;
          color: #5f6464;
          font-style: normal;
        }
      }
      div.float {
        display: flex;
        align-items: center;
        justify-content: space-between;
        div.right {
          width: 50%;
          height: 50%;
        }
        div.text {
          text-align: left;
          color: #909399;
          font-weight: 500;
          font-style: 16px;
          p.title2 {
            font-size: 16px;
            font-family: "HarmonyOsSansM";
            color: #2a2e2e;
            font-style: italic;
            padding: 2px 0;
          }
          p.textItem {
            line-height: 20px;
            padding: 2px 0 16px;
            font-size: 14px;
          }
          p.textItem:last-of-type {
            padding: 2px 0 0;
          }
        }
      }
    }
  }
  div.bottom {
    font-size: 12px;
    text-decoration: none;
    color: #bbb;
    padding: 20px 0;
    cursor: pointer;
  }
  // 高德地图
  #mapContainer {
    width: 100%;
    height: 200px;
    background-color: #ffffff;
  }
}
</style>